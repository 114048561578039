<template>
  <div class="attribution-form-wrapper">
    <div
      class="columns is-centered"
      v-if="currentUser && currentUser.email && !currentUser.attributionSource"
    >
      <div class="column is-6 mb-4">
        <b-notification type="is-info is-light" :closable="false">
          <h3 class="title is-4">How did your hear about us ?</h3>
          <h4 class="subtitle is-6">This helps a lot, thanks in advance!</h4>
          <div v-if="!selectChoice">
            <div
              v-for="choice in attributionChoices"
              :key="choice.val"
              class="py-1"
            >
              <b-button
                expanded
                class="has-text-left"
                @click="clickChoice(choice.val)"
                >{{ choice.text }}</b-button
              >
            </div>
          </div>
          <div v-if="selectChoice === 'other'">
            <b-button expanded disabled>Other</b-button>
            <b-field
              label="Please precise :"
              class="mt-2"
              custom-class="has-text-grey"
            >
              <b-input
                ref="preciseinput"
                v-model="attributionOtherText"
              ></b-input>
            </b-field>
            <div class="buttons">
              <b-button
                @click="selectChoice = null"
                type="is-light"
                icon-left="arrow-left"
                >Back</b-button
              >
              <b-button @click="submitAttrib" type="is-info">Submit</b-button>
            </div>
          </div>
        </b-notification>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "attribution-form",
  computed: {
    ...mapGetters(["currentUser", "attributionChoices"])
  },
  methods: {
    clickChoice(choiceVal) {
      this.selectChoice = choiceVal;
      if (choiceVal !== "other") {
        this.submitAttrib();
      } else {
        setTimeout(() => {
          this.$refs.preciseinput.focus();
        }, 5);
      }
    },
    submitAttrib() {
      this.$store
        .dispatch("updateUserProfile", {
          uid: this.currentUser.uid,
          attributionSource: this.selectChoice,
          ...(this.attributionOtherText && {
            attributionOtherText: this.attributionOtherText
          })
        })
        .then(() => {
          this.$buefy.toast.open("Thanks for letting us know !");
        });
    }
  },
  data() {
    return {
      selectChoice: null,
      attributionOtherText: null
    };
  }
};
</script>

<style lang="scss" scoped></style>
