<template>
  <div class="intro-tab">
    <section class="section" v-if="currentUser">
      <div class="container">
        <h1 class="title is-marginless">
          Welcome!
        </h1>
        <div class="section mt-2">
          <attribution-form></attribution-form>
          <div class="title is-4">A few steps to help you get started :</div>
          <div class="profile-info-bloc box">
            <ul>
              <li>
                <div
                  class="is-flex is-size-4"
                  :class="{ 'is-disabled': !onboardingSteps.hasCreated }"
                >
                  <b-icon
                    icon="check-circle"
                    :type="
                      onboardingSteps.hasCreated ? 'is-success' : 'is-grey'
                    "
                    size="is-medium"
                  ></b-icon>
                  <span class="ml-2">Create your first game</span>
                </div>
                <div class="ml-6 mt-2" v-if="!onboardingSteps.hasCreated">
                  <router-link to="/dashboard/create">
                    <b-icon icon="arrow-right" size="is-small"></b-icon>Click
                    here to create a game.</router-link
                  >
                  <br /><em>
                    Simply choose a name and submit to start with standard
                    parameters.
                  </em>
                </div>
                <div
                  class="ml-6 mt-2 is-italic"
                  v-if="onboardingSteps.hasCreated"
                >
                  Congrats! You have created
                  <b
                    >{{ onboardingSteps.hasCreated }} game{{
                      onboardingSteps.hasCreated > 1 ? "s" : ""
                    }}</b
                  >
                </div>
              </li>
              <li class="mt-4">
                <div
                  class="is-flex is-size-4"
                  :class="{
                    'is-disabled': !onboardingSteps.finishedGames.length
                  }"
                >
                  <b-icon
                    icon="check-circle"
                    :type="
                      onboardingSteps.finishedGames.length
                        ? 'is-success'
                        : 'is-grey'
                    "
                    size="is-medium"
                  ></b-icon>
                  <span class="ml-2">Finish your first game</span>
                </div>
                <div
                  class="ml-6 mt-2"
                  v-if="
                    !onboardingSteps.finishedGames.length &&
                      onboardingSteps.hasCreated &&
                      onboardingSteps.notFinishedGame
                  "
                >
                  <b-icon icon="arrow-right" size="is-small"></b-icon> Try
                  finishing this game :
                  <router-link
                    class="has-text-weight-bold"
                    :to="{
                      name: 'game',
                      params: { id: onboardingSteps.notFinishedGame.id }
                    }"
                  >
                    {{ onboardingSteps.notFinishedGame.name }}
                  </router-link>
                  <br />
                  <em>
                    Get to know the app by playing a full game as a participant
                    against the computer.
                  </em>
                </div>
                <div
                  class="ml-6 mt-2 is-italic"
                  v-if="
                    onboardingSteps.finishedGames &&
                      onboardingSteps.finishedGames.length
                  "
                >
                  Good job, you finished
                  <b>{{
                    onboardingSteps.finishedGames.length > 1
                      ? onboardingSteps.finishedGames.length + " games"
                      : onboardingSteps.finishedGames[0].name
                  }}</b>
                </div>
              </li>
              <li class="mt-4">
                <div
                  class="is-flex is-size-4"
                  :class="{ 'is-disabled': !onboardingSteps.hasPlan }"
                >
                  <b-icon
                    icon="check-circle"
                    :type="onboardingSteps.hasPlan ? 'is-success' : 'is-grey'"
                    size="is-medium"
                  ></b-icon>
                  <span class="ml-2">Upgrade your account plan</span>
                </div>
                <div class="ml-6 mt-2" v-if="!onboardingSteps.hasPlan">
                  <router-link to="/dashboard/subscription">
                    <b-icon icon="arrow-right" size="is-small"></b-icon>Discover
                    the different options here.</router-link
                  >
                  <br /><em>
                    This will allow you to share your games and play with other
                    participants.</em
                  >
                </div>
                <div class="ml-6 mt-2 is-italic" v-if="onboardingSteps.hasPlan">
                  Thanks for your trust - Your account is upgraded with the
                  following plans:
                  <p
                    v-for="gt in Object.values(gameTypes)"
                    :key="`choose-${gt.type}`"
                  >
                    <b>{{ gt.name }}:</b>
                    {{
                      onboardingSteps.hasPlan[gt.type] &&
                      onboardingSteps.hasPlan[gt.type].active
                        ? onboardingSteps.hasPlan[gt.type].planName
                        : "Free trial"
                    }}
                  </p>
                </div>
              </li>
              <li class="mt-4">
                <div
                  class="is-flex is-size-4"
                  :class="{ 'is-disabled': !onboardingSteps.hasSharedGames }"
                >
                  <b-icon
                    icon="check-circle"
                    :type="
                      onboardingSteps.hasSharedGames ? 'is-success' : 'is-grey'
                    "
                    size="is-medium"
                  ></b-icon>
                  <span class="ml-2">Play multi-player games</span>
                </div>
                <div class="ml-6 mt-2" v-if="!onboardingSteps.hasSharedGames">
                  <em>
                    Share games with students or team members, and get the full
                    benefits of the app !</em
                  >
                </div>
                <div class="ml-6 mt-2">
                  <a
                    href="https://zensimu.com/guide/"
                    target="_blank"
                    rel="noreferrer"
                    >Read the Instructor Guide
                  </a>
                  for tips on how to organize your sessions in practice.
                </div>
                <div class="ml-6 mt-2">
                  <b-icon icon="alert" size="is-small"></b-icon>
                  <b> Browser compatibility :</b>
                  <span class="ml-2"
                    >Participants should access the game using a modern web
                    browser such as Chrome, Firefox, Safari or Edge. The
                    simulations can't be run using Internet Explorer. Note that
                    access is not possible from China due to the
                    <em>Great Firewall</em> restrictions. Please check that the
                    network of your company doesn't prevent the access to the
                    website.
                  </span>
                </div>
                <div
                  class="ml-6 mt-2 is-italic"
                  v-if="onboardingSteps.hasSharedGames"
                >
                  Don't hesitate to send any feedback about your experience at
                  <b>hello@zensimu.com</b>
                </div>
              </li>
            </ul>
          </div>
          <!-- <div class="buttons">
            <b-button
              type="is-info"
              tag="router-link"
              to="/dashboard/games"
              rounded
              icon-left="arrow-left"
              >My Games</b-button
            ><b-button
              rounded
              type="is-info is-light"
              class="updates-headway is-hidden-mobile"
            >
              🎁 New features
            </b-button>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// /* global Headway */
import { mapGetters } from "vuex";
import AttributionForm from "@/components/marketing/AttributionForm.vue";

export default {
  name: "introTab",
  components: { AttributionForm },
  // mounted() {
  //   let config = {
  //     selector: ".updates-headway",
  //     account: "JP0AMJ"
  //   };
  //   Headway.init(config);
  // },
  computed: {
    ...mapGetters([
      "currentUser",
      "userGamesList",
      "onboardingSteps",
      "gameTypes"
    ])
  }
};
</script>
<style lang="scss">
.intro-tab {
  .is-flex {
    align-items: center;
  }
  // .updates-headway {
  //   padding-right: 40px;
  //   margin-left: 5%;
  // }
  #HW_badge_cont {
    position: absolute;
    width: 100%;
    right: 0;
    #HW_badge {
      display: block;
      margin-left: -30px;
      left: 100%;
    }
  }
}
</style>
